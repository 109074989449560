<template>
    <div class="account-reset">
        <edit :api="api" :fs="userResetPasswordFields" @saved="saved" customClassName="none" title="Reset Password"></edit>
    </div>
</template>

<script>
import { userResetPasswordFields } from "@/config";
import Edit from "@/components/Edit";

export default {
    props: ["id"],
    components: {
        Edit
    },
    computed: {
        userResetPasswordFields() {
            return userResetPasswordFields;
        },
        api() {
            return `users/reset/${this.id}`;
        }
    },
    methods: {
        saved(result) {
            this.$store.commit("setMessage", "Success! Your password has been changed.");
            this.$router.push("/");
        }
    }
};
</script>
<style lang="less">
.account-reset {
    min-height: 700px;
    .md-card {
        max-width: 1000px;
        margin: auto;
    }
}
</style>
